<template>
  <div id="ArticlesList">
    <div class="posFix" ref="posFix">
      <div class="topSearch" ref="otherFix">
        <div class="right" @click="changePath('/MyCollection')">
          <img src="../../assets/image/findOut/article.png" />
        </div>
        <van-search
          v-model="search"
          shape="round"
          background="#23252E"
          placeholder="请输入搜索关键词"
          @input = focusChange
          style="flex:1"
        />
      </div>
      <div class="double">
        <div class="titleTop"  :class="{'bottomHeight':!toTop,'topHeight':toTop}">
          <div :class="{'active':categoryId == ''}"   @click="changePath('')">最新</div>
          <!-- <div :class="{'active':categoryId == 'jd'}"   @click="changeJd()">监管</div> -->
          <div :class="{'active':categoryId == i.id}" v-for="(i,item) in getCategory" :key="item"  @click="changePath(i.id)">{{i.name}}</div>
        </div>
        <div  class="arrow" :class="{'bottom_arrow':!toTop,'top_arrow':toTop}" @click="toTop = !toTop"></div>
      </div>
    </div>

    <div class="listOne" ref="topMost" >
      <div @click.stop="toLink(articleHeadline.category_id,articleHeadline.id,articleHeadline.wx_url)">
        <img :src="articleHeadline.thumbimgurl ? Global.changeImg(articleHeadline.thumbimgurl) : '../../assets/image/home/index.png'"/>
        <div>
          <p>{{articleHeadline.title}}</p>
          <p><span class="arocess1">头条</span>保观发布 {{Global.clearWhite($moment(new Date(Date.parse(articleHeadline.created_at))).fromNow())}}</p>
        </div>
      </div>
    </div>

    <div ref="other"></div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      class="listThree "
    >
      <div class="list" v-for="(i,item) in getDocList" :key="item" @click="toLink(i.category_id,i.id,i.wx_url)">
        <div>
          <div >{{i.title}}</div>
          <div>
            <span>保观发布 {{Global.clearWhite($moment(new Date(Date.parse(i.created_at))).fromNow())}}</span>
            <span><van-icon name="eye-o" style="margin:0 0.05rem 0 0;font-size:0.14rem" />{{i.views}}</span></div>
        </div>
        <div  v-if="i.thumbimgurl" :style="Global.changeImgBg(i.thumbimgurl)" >
        </div>
        <div v-else class="bac">
        </div>
      </div>
    </van-list>
    
      
  </div>
</template>
<script>
import start from '../../assets/image/findOut/start.png'
import startn from '../../assets/image/findOut/startn.png'
export default {
  name: "ArticlesList",
  data(){
    return{
      show:false,
      toTop:false,
      letters:false,
      getCategory:[],//文章分类
      categoryId:'',//文章分类id
      articleHeadline:'',//文章头条
      getDocList:[],//文件列表

      finished:false,
      loading:true,
      next_page_url:'',
      sort:0,
      cateId:'',
      value:'',
      getCollectIds:[],//收藏的文件id
      search:'',
      isVip:'',
      Sup:''
    }
  },
  created(){
    this.Global.getShareConfig({
      title: '保观资料库  | 保观大咖会',
      desc: '幸运的是，今天的我们不用担心过于专注而被野兽吃掉；不幸的是，知识爆炸的今天我们必须学会过滤信息。'
    },this)
  },
  // ?Sup=1
  mounted() {
    this.$refs.topMost.style.paddingTop = this.$refs.posFix.offsetHeight +'px'
    this.Sup = this.$route.query.Sup 
    var url = ''
    if(this.Sup){ //监管类型
      url = '/article/getCateArticle?categoryId=50&search='+this.search
      this.categoryId = 50;this.toTop = true;
    }else{
      url = '/article/getCateArticle?categoryId=&search='+this.search
    }
       // 文章分类
      this.axios({
        method: 'GET',
        url: '/article/getCategory',
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        async:false
      }).then((res) => {
        if(res.data.code == 0){
          this.getCategory = res.data.data
        }
      })
      // 首页头条
      this.axios({
        method: 'GET',
        url: '/article/articleHeadline',
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        async:false
      }).then((res) => {
        if(res.data.code == 0){
          this.articleHeadline = res.data.data.headline
        }
      })
    
    // 文件列表
    this.axios({
      method: 'GET',
      url: url,
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      async:false
    }).then((res) => {
      if(res.data.code == 0){
        this.loading = false
        this.getDocList = res.data.data.list.data;
        this.next_page_url = res.data.data.list.next_page_url
        this.isVip = res.data.data.userStatus
      }
    })
  },
  methods:{
    focusChange(){
      window.scrollTo(0,0)
      var url = ''
      if(this.Sup){ //监管类型
        url = '/article/getCateArticle?categoryId=50&search='+this.search
      }else{
        url = '/article/getCateArticle?categoryId='+this.categoryId+'&search='+this.search
      }
      this.axios({
        method: 'GET',
        url: url,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.loading = false
          this.finished = false
          this.getDocList = []
          if(res.data.data){
            this.getDocList = res.data.data.list.data;
            this.next_page_url = res.data.data.list.next_page_url
          }else{
            this.next_page_url = ''
          }
          if(!this.next_page_url || !res.data.data.list.data.length || !res.data.data){
            this.finished = true
          }
        }
      })
    },
    toLink(categoryId,id,url){
      // if(this.isVip == 2){
        this.axios({
          method: 'PATCH',
          url: '/article/addArticleViews/'+id,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then(() => {
          if(url){
            window.location.href = url
          }else{
            this.$router.push('/ArticleDetail?categoryId='+categoryId+'&articleId='+id)
          }
        })
      // }else{
      //   this.$toast('请先购买会员')
      //   var _this = this;
      //   setTimeout(function(){
      //     _this.$router.push('/MemberProduct')
      //   },2000)
      // }
    },
    changeJd(){
      this.categoryId = 'jd'
      // 文件列表
      this.axios({
        method: 'GET',
        url: '/article/getCateArticle?categoryId=50&search='+this.search,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        async:false
      }).then((res) => {
        if(res.data.code == 0){
          this.loading = false
          this.getDocList = res.data.data.list.data;
          this.next_page_url = res.data.data.list.next_page_url
          this.isVip = res.data.data.userStatus
        }
      })
    },
    changePath(categoryId){
      this.show = false
      this.getDocList = []
      this.categoryId = categoryId
      this.firstLoad()
    },
    checkCateId(){
      this.letters = false
      this.getDocList = []
      this.sort = 0
      this.next_page_url = ''
      this.firstLoad()
    },
    firstLoad(){
      window.scrollTo(0,0)
      var url = ''
      this.next_page_url = ''
      if(this.Sup){ //监管类型
        url = '/article/getCateArticle?categoryId=50&search='+this.search
      }else{
        url = '/article/getCateArticle?categoryId='+this.categoryId+'&search='+this.search
      }
      this.axios({
        method: 'GET',
        url: url,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.loading = false
          this.finished = false
          this.getDocList = []
          if(res.data.data){
            console.log(res.data.data.list)
            this.getDocList = res.data.data.list.data;
            this.next_page_url = res.data.data.list.next_page_url
            console.log(this.next_page_url)
          }else{
            this.next_page_url = ''
          }
          if(!this.next_page_url || !res.data.data.list.data.length || !res.data.data){
            this.finished = true
          }
        }
      })
    },
    onLoad(){
      if(this.next_page_url){
        var url;
        url =  this.next_page_url+'&categoryId='+this.categoryId+'&search='+this.search

        this.axios({
          method: 'GET',
          url: url,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            this.loading = false
            if(res.data.data){
              this.getDocList.push(...res.data.data.list.data)
              this.next_page_url = res.data.data.list.next_page_url
            }else{
              this.next_page_url = ''
            }
            if(!this.next_page_url || !res.data.data.list.data.length || !res.data.data){
              this.finished = true
            } 
          }
        })
      }else{
        this.loading = false;
        this.finished = true
      }
    },
    toStar(docId){
      this.axios({
        method: 'PATCH',
        url: '/doc/collectFile',
        data:{
          status:1,
          docId:docId
        },
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.getCollectIds.push(docId)
          this.$toast({
            message: '收藏成功',
            icon: start,
          })
        }
      })
    },
    noStar(docId){
      this.axios({
          method: 'PATCH',
          url: '/doc/collectFile?sort='+this.sort+'&docId='+docId,
          data:{
            status:0,
            docId:docId
          },
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            var index = this.getCollectIds.indexOf(docId);
            this.getCollectIds.splice(index, 1);
            this.$toast({
              message: '取消收藏',
              icon: startn,
            })
          }
        })
    },    
  }
};
</script>
<style lang="less" scoped>
#ArticlesList {
  .posFix{
    position: fixed;
    top: 0;
    width: 100%;
    background: #16171D;
    z-index: 10;
    .double{
      padding: 0 0.2rem 0.05rem 0.15rem;
      position: relative;
      .arrow{
        width: 0.06rem;
        height: 0.06rem;
        border-top: 1px solid #CFD0E0;
        border-right: 1px solid #CFD0E0;
        position: absolute;
        right: 0.15rem;
      }
      .bottom_arrow{
        transform: rotate(135deg);
        bottom: 0.2rem;
      }
      .top_arrow{
        transform: rotate(-45deg);
        bottom: 0.15rem;
      }
    }
    .bottomHeight{
       height: 0.5rem;
    }
    .topHeight{
      height: auto;
    }
    .titleTop {
      background: #23252E;
      font-size: 0.14rem;
      color: #9091A3;
     
      overflow: hidden;
      > div {
        margin-bottom: 0.1rem;
        margin-right: 0.18rem;
        position: relative;
        display: inline-block;
        height: 0.16rem;
      }
      .active {
        font-weight: 600;
        color: #f6f7fd;
      }
      .active::after {
        position: absolute;
        content: "";
        min-width: 0.18rem;
        height: 2px;
        background: #3890ff;
        left: 0;
        right: 0;
        margin: auto 0.05rem;
        bottom: -0.05rem;
      }
    }
    >div{
      background: #23252E;
    }
  }
  .topSearch{
    display: flex;
    padding: 0.15rem 0.15rem 0.12rem;
    .right{
      display: flex;
      align-items: center;
      color: #9091A3;
      font-size: 0.12rem;
      >img{
        width: 0.6rem;
        margin-right: 0.12rem;
      }
    }
  }
   .listThree{
    padding: 0 0.15rem;
    background: #23252E;
    .blue{
      color: #3890FF;
    }
    >div:last-child{
      border: none;
    }
    >div{
      padding: 0.15rem 0;
      display: flex;
      align-items: center;
      border-bottom:1px solid rgba(112, 112, 112, 0.16);
      >div:last-child{
        width: 0.73rem;
        height: 0.62rem;
        margin-left: 0.12rem;
        background-size: cover;
        overflow: hidden;
        >img{
          width:100%;
          height: 100%;
          object-fit: cover;
        }
      }
      >.bac{
        background:url(../../assets/image/home/index1.png)
      }
      >div:first-child{
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        color: #F6F7FD;
        >div:last-child{
          color: #989AB1;
          font-size: 0.12rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0.08rem 0 ;
        }
        >div:first-child{
          color: #F6F7FD;
          font-size: 0.14rem;
          margin-top:0.1rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          >span:last-child{
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .listOne{
    background: #16171D;
    overflow: hidden;
      >div{
        margin: 0.15rem 0;
        padding: 0 0.15rem;
        background: #23252E;
        >img{
          width: 100%;
          padding-top: 0.15rem;
        }
        >div{
          border-radius: 0px 0px 5px 5px;
          padding: 0.1rem 0 0.1rem 0;
          >p:last-child{
            display: flex;
            align-items: center;
            font-size: 0.12rem;
            color: #989AB1;
            >span{
              padding: 0.02rem 0.05rem;
              font-size: 0.12rem;
              color: #F6F7FD;
              transform: scale(0.8);
              border-radius: 0.02rem;
              margin-right: 0.065rem;
            }
            .arocess1{
              background: linear-gradient(90deg, #FF636F 0%, #FE5E67 50%, #FC7277 100%);
            }
            .arocess2{
              background: linear-gradient(90deg, #FF7E4E 0%, #FF9544 100%);
            }
            .arocess3{
              background: linear-gradient(90deg, #3C94FF 0%, #4CADFF 100%);
            }
          }
          >p:first-child{
            color: #F6F7FD;
            font-size: 0.14rem;
            margin-bottom: 0.05rem;
          }
        }
      }
    }


 
  
  

 
  >div{
    background: #23252E;
  }
}
</style>